.survey-form input[type="text" i] {
  /* text field edith */


  box-sizing: border-box;

  width: 310px;
  height: 40px;

  /* DARK GREY */

  border: 1px solid #979797;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  background-color: #404040;
  color: white;
  padding: 10px;
}

.survey-form textarea {
  /* text field edith */


  box-sizing: border-box;

  width: 310px;
  min-height: 100px;

  /* DARK GREY */

  border: 1px solid #979797;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  background-color: #404040;
  color: white;
  padding: 10px;
}

.survey-form input[type="email" i] {
  /* text field edith */


  box-sizing: border-box;

  width: 310px;
  height: 40px;

  /* DARK GREY */

  border: 1px solid #979797;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  background-color: #404040;
  color: white;
  padding: 10px;
}

.survey-form  input[type="checkbox" i] {
  box-sizing: border-box;

  left: 0;
  margin-right: 10px;
  top: 0;
  bottom: 0;

  background-color: #404040;

  /* WHITE */

  border: 2px solid #FFFFFF;
  border-radius: 4px;
}

.inputs-list {
  list-style: none;
  padding-inline-start: 20px;
}

.survey-form  input[type="submit" i] {
  /* Button */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  cursor: pointer;
  border-style: none;

  width: 100%;
  height: 40px;
  right: 0;
  top: 0;

  /* BLACK */

  background: #000000;
  border-radius: 8px;
}

.survey-form  input[type="submit" i]:hover {

  background: #4d4d4d;
  border-radius: 8px;
}

.survey-form select {
  /* Dropdown */
  background: #4d4d4d;
  border-radius: 8px;
  padding: 10px;
}

.survey-form  .hs-submit .hs-button {
  background-color: #000000;
  margin: 20px 0;

  color: #FFFFFF;
}

.survey-form  .hs-form {
  margin: 20px;
}

.survey-form  .hs-form-field {
  margin: 10px 0;
}

.hs-form * {
  color: #FFFFFF;
}

.hs-form .input {
  padding-top: 8px;
  padding-bottom: 10px;

}