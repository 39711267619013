:root, [data-amplify-theme] {
  --amplify-colors-font-primary: #333;
  /* you can also use references: */
  --amplify-colors-font-secondary: var(--amplify-colors-neutral-60);
}

/* All components have a class name starting with `amplify` */
.amplify-button {
  /* Frame 4099 */

  height: 36px;

  /* BLACK */
  background: #000000;
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 5;
  flex-grow: 0;
}

.amplify-button--link {
  color: #FFFFFF;
}

.amplify-button--link:hover {
  color: #FFFFFF;
}

.amplify-button:hover {
  background: gray;
}

[data-amplify-authenticator] {
  max-width: 400px;
  width: 100%;
}

[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none;
  border-style:none;
}

[data-amplify-authenticator] [data-amplify-container] {
  width: 100%;
}

[data-amplify-footer] .amplify-button {
  color: white;
  border: 1px solid #5D82AD;
}

[data-amplify-form]{
  background-color: #404040;
}

[data-amplify-footer] {
  background-color: #7f7f7f;
}

.amplify-text {
  color: #FFFFFF;
}
.amplify-text--error {
  color: #cb4a4a;
}

.amplify-label {
  color: #FFFFFF;
}

.amplify-input{
  background-color: #ffffff;
  border-radius: 8px;
}

.amplify-icon {
  color: #FFFFFF;
}

.amplify-tabs[data-indicator-position=top] {
  display: none;
}

.amplify-heading {
  color: #FFFFFF;
}

.amplify-checkbox__icon {
  background-color: hsl(0deg 0% 100% / 0%);
}

.amplify-checkbox__button--focused  {
  background-color: hsl(0deg 0% 100% / 0%);
}

