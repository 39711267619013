.team-member-row{
  margin: 10px 10px;
}

.pending{
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  height: 40px;
  margin-right: 6px;
}

.ul{
  margin-left: -3rem;
}
