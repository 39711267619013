$scene-list-width: 500px;

.list-card-container.project-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.list-card-container.scene-container {
  background-color: #404040;
}

.list-card-container {
  padding: 11px;

  background-color: #4f4f4f;

  margin: 1px 0;

  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.05em;

  /* ANIMOTIVE COLOURS/Main text */

  /* Inside auto layout */

  display: flex;
  gap: 10px;

  justify-content: center;

  border-radius: 8px;


  width: 340px;
}

.list-header {
  max-width: 90%;
  width: 100%;
}

.list-header p {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

@media screen and (max-width: 600px) {
  .list-card-container {
    width: 100%;
  }
}

.active-card-container {
  background-color: #333333;
}

.scene-selection-button {
  width: 100%;
  text-wrap: none;
}

.scene-selection-dropdown {
  max-width: $scene-list-width;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #404040;
}

.open-scene-button {
  margin-top: 20px;
  flex-grow: 1;
  text-wrap: none;
  width: fit-content;
  min-width: 90px !important;
}

.open-scene-button p {
  overflow: auto;
  width: max-content;
}

.list-component-background {
}