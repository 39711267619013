.nav-bar-left {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
  height: 100%;
  padding-top: 12px;
}

.nav-bar-right {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  padding-top: 12px;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
}

.navbar-project-title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

@media screen and (max-width: 600px) {
  .navbar-project-title-text {
    font-weight: 900;
  }

  .nav-bar {
    padding: 0;
  }
}

@media screen and (max-width: 900px) {
  .nav-bar {
    width: 100%;
  }

  .navbar-project-title {
    width: 100%;
    background-color: #1E1E1E;
    height: 56px;
    padding: 0 20px;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 900px) {
  .navbar-project-title {
    background-color: #1E1E1E;
    border-radius: 8px 8px 0 0;
    margin-bottom: -12px;
    padding: 0 20px 12px;
  }
}

@media screen and (max-width: 600px) {
  .return-button {
    justify-content: flex-start;
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .navbar-project-title {
    display: flex;
    height: 62px;

    min-width: 0;
    align-items: center;
    font-weight: 800;
  }

  .return-button {
    margin: 0 20px;
    display: flex;
  }
}

.MuiTabs-flexContainer {
  justify-content: space-between;
}

.mobile-nav-project-only {
  padding: 0;
}

.mobile-nav-project-stack {
  align-items: flex-start;
}