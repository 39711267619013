@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

$color: #9aaecd;
body {
  background-color: #7F7F7F;
  height: 100%;
}

.MuiToolbar-root {
  background-color: #1E1E1E;
}

.MuiButtonBase-root {
  font-size: 1rem !important;
}

.inner-box {
  max-width: 340px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.sidebar {
  margin-left: 20px;
}

.feature-box {
  max-width: 1000px;
    margin: 0 auto;
  display: flex;
  justify-content: center;
}

.centre-box {
  display: flex;
  padding: 18px;
  min-height: 75vh;
  background-color: #7F7F7F;
  justify-content: center;
  margin-bottom: 64px;
  height: 100%;
  border: thin solid #000000;
}

@media screen and (min-width: 340px){
  .centre-box .inner-box {
    min-width: 340px;
  }
}

@media screen and (min-width: 600px) {
  .centre-box {
    min-width: 598px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .centre-box {
    padding: 10px 20px;
    margin-left: 0;
    min-width: 100vw;
  }
}

.checkout-displayer {
  color: #000000;
  align-items: center;
  padding: 18px;
  min-height: 75vh;
  background-color: #FFFFFF;
  border-radius: 20px;
  width: 100%;
}

.featured-card {
  background-color: #2F80ED;
}

.thumbnail-slider {
  width: 100%;
  height: 9vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
  border-radius: 10px;
}

.vertical-slider{
  font-size: clamp(0.75rem, -0.875rem + 7.5vw, 1rem);
  padding: 0 20px 20px 20px;
}

.vertical-slider img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.landing-page-dialog img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.landing-page-dialog p {
  font-size: clamp(0.75rem, -0.875rem + 7.5vw, 1rem);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.landing-page-dialog h1 {
  font-size: clamp(1.5rem, -0.875rem + 8.333vw, 2.5rem);
  margin: 0;
  padding: 0;
}

.landing-page-dialog h4 {
  font-size: 1rem;
  margin: 0;
  padding-bottom: 10px;
}

.landing-page-dialog a {
  color: #79b7f2;
  text-decoration: none;
}

.information-boxes{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 60vh;
}