.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #868B9A;
}

.linkState {
  cursor: pointer;
}

.download-page-body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    background-color: #F2EAE8;
    padding: 16px;
  color: #000000;
}

.download-page-body img{
  height: auto;
  width: auto;
  display: block;
  max-width: 100px;
  min-height: 50px;
  object-fit: contain;
}

.animotive-icon-box {
  height: auto;
  width: auto;
  display: block;
  max-width: 180px;
  min-height: 50px;
  object-fit: contain;
  padding: 16px;
  margin-top: 16px;
}

.download-page-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #3F424B;
    width: 100%;
  padding: 20px;
}

.download-page-header img{
  height: auto;
  width: auto;
  display: block;
  max-width: 50px;
  object-fit: contain;
}

.download-page-header p{
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.unity-link {
  text-decoration: underline;
  cursor: pointer;
}

.video-responsive {
  overflow: hidden;
  position: relative;
  min-height: 40vh;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.download-button {
  font-size: 1.5rem !important;
  padding: 15px 20px !important;
  box-shadow: 0px 8px 8px 0px #00000080 !important;
}
